/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useRef, useState } from "react"

import Header from "./header"
import "./layout.css"

import { Global, css } from "@emotion/core"
import { StylesProvider, Button } from "@material-ui/core"
import Footer from "./footer"
import ResponsiveView from "../responsive/view"
import { theme } from '../common/site-variables'
import FloatingCart from './floating-cart'
import { navigate } from 'gatsby'
import { refreshAccessToken } from "../services/auth-service"

interface PropTypes {
  children: JSX.Element[] | string
}

const Layout = (props: PropTypes) => {
  // useEffect(() => {
  //   refreshAccessToken();
  // }, []);

  return (
    <>
      <StylesProvider injectFirst>
        <ResponsiveView render={(isMobile) => <Header isMobile={isMobile} />} />

        <div className="wrapper">
          <Global
            styles={css`
              * {
                box-sizing: border-box;
              }

              body {
                margin: 0;
                width: 100%;
                height: 100%;
              }

              .wrapper {
                position: relative;
              }

              main {
                position: relative;
                padding-top: 80px;
                padding-bottom: 5%;
                min-height: 100vh;
              }

              .productBtn {
                position: fixed;
                top: 50%;
                left: -1vw;
                min-height: 18vh;
                z-index: 5;
                width: 5vw;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                
                background-color: ${theme.cs_yellow};

                &:hover {
                  background-color: ${theme.cs_dark_blue};
                  color: white;
                }

                .text {
                  transform: translateY(-50%);
                  transform: rotate(-90deg);
                }
              }
            `}
          />

          <main>
            <Button
              className="productBtn"
              variant="contained"
              onClick={() => navigate('/products')}
            >
              <span className="text">PRODUCTS</span>
            </Button>
            {props.children}
          </main>

          <ResponsiveView render={isMobile => <Footer isMobile={isMobile} />} />
        </div>
      </StylesProvider>
    </>
  )
}

export default Layout
