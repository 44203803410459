import React from "react"

/**@jsx jsx */
import { jsx, css } from "@emotion/core"
import { TextField, Button } from "@material-ui/core"
import { theme } from '../common/site-variables'
import { flexRowCenterAll } from '../common/format-styles'

const RequestBanner = () => {

  const styles = css(`
        width: 100%;
        height: 20vh;
        background-color: ${theme.cs_yellow};

        h3 span {
            color: ${theme.cs_blue};
        }
        

        form {
            display: flex;
            justify-content: space-between;
            padding-left: 2%; 

            .textField {
                background-color: rgba(24,71,99, .4);
                margin-right: 2%;

                .MuiInputLabel-outlined {
                    color: white;
                }
            }

            button {
                color: white;
                background-color: ${theme.cs_blue};
            }
        }
        .noMargin {
          margin: 0 !important;
        }
    `)

  return (
    <div css={[styles, flexRowCenterAll]}>
      <h3 className="noMargin">
        Request a <span>Service Repair</span> 
      </h3>
    </div>
  )
}

export default RequestBanner
