import React, { useRef } from "react"

/**@jsx jsx */
import { jsx, css } from "@emotion/core"
import RequestBanner from "./request-banner"
import EnquiryForm from "./enquiry-form"
import CopyrightOutlined from "@material-ui/icons/CopyrightOutlined"
import { theme } from '../common/site-variables'
import { flexRow, flexAlignCenter, flexColCenterAll, flexCol } from '../common/format-styles'

interface PropTypes {
  isMobile: boolean;
}

const Footer = (props: PropTypes) => {

  const { isMobile } = props;

  const FooterContStyles = css(`
        position: relative;
        bottom: 0;
        width: 100%;
        z-index: 1200;

        .footerContent {
            width: 100%;
            background-color: ${isMobile ? theme.cs_darker_blue : 'white'};

            .enquiry {
              position: relative;
              padding: 3%;
              width: ${isMobile ? 100 : 60}%;
              background-color: ${theme.cs_lighter_blue};
              border-radius: 0 7% 0 0 / 0 15% 0 0;
              box-shadow: 2px 5px 7px rgba(0, 0, 0, .2);
              margin-top: -2%;

              .copy {
                position: absolute;
                bottom: 5%;
              }

            }

            .contactDiv {
              position: relative;
              width: ${isMobile ? 100 : 40}%;
              color: ${isMobile ? theme.cs_lighter_blue : theme.cs_dark_blue};

              address {
                width: 100%;
              }

              address > span, a {
                margin-bottom: 2%;
                color: ${isMobile ? theme.cs_lighter_blue : theme.cs_dark_blue};
              }

              .credits {
                position: ${isMobile ? 'relative' : 'absolute'};
                bottom: 5%;

                .swizzD {
                  color: ${theme.cs_yellow};
                }

                .copy {
                  display: block;
                }
              }
        }
      }
    `)

  return (
    <footer css={FooterContStyles}>
      <RequestBanner />

      <div className="footerContent" css={isMobile ? flexCol : flexRow}>
        <div className="enquiry">
          <EnquiryForm />
          {!isMobile && <span className="copy" css={flexAlignCenter}>
            <CopyrightOutlined /> {new Date().getFullYear()} GC Technology & Mobility Solutions
          </span>}
        </div>

        <div className="contactDiv" css={flexColCenterAll}>
          <address css={flexColCenterAll}>
            <span>62 Johns Lane, Kingston, Jamaica</span>
            <a href="tel:8768491161">876.849.1161</a>
            <a href="mailto:hello@gctechja.com">hello@gctechja.com</a>
          </address>

          <span className="credits">
            Design and code by{" "}
            <a
              href="https://swizzdigital.com/"
              target="_blank"
              className="swizzD"
            >
              Swizz Digital
            </a>
          
          </span>

          {isMobile && <span className="copy" css={flexAlignCenter}>
              <CopyrightOutlined /> {new Date().getFullYear()} GC Technology & Mobility Solutions
            </span>}

        </div>
      </div>
    </footer>
  )
}

export default Footer
