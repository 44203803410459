import React, { useState, useEffect } from "react"

/**@jsx jsx */
import { jsx, css } from "@emotion/core"
import { TextField, Button, Snackbar } from "@material-ui/core"
import axios from "axios"
import { isNull, emailIsValid } from "../helper/formValidors"

export interface FormControl {
  value: string
  touched: boolean
  valid: boolean
  errorText?: string
}

interface FormData {
  firstName: FormControl
  lastName: FormControl
  email: FormControl
  phone: FormControl
  message: FormControl
  valid: boolean
}

export const inititalValues = {
  value: "",
  touched: false,
  valid: false,
}

const initForm = () => {
  return {
    firstName: { ...inititalValues },
    lastName: { ...inititalValues },
    email: { ...inititalValues },
    phone: { ...inititalValues },
    message: { ...inititalValues },
    valid: false,
  }
}

const EnquiryForm = () => {
  const [formValid, setFormValid] = useState(false)
  const [formData, setFormData] = useState<FormData>(initForm())

  useEffect(() => {
    setFormValid(
      formData.email.valid &&
        formData.firstName.valid &&
        formData.lastName.valid &&
        formData.message.valid
    )
  }, [formData])

  const handleChange = (e: React.FormEvent) => {
    const target = e.target as HTMLInputElement
    const name = target.name
    const value = target.value
    const notNull = !isNull(value)
    const emailValid = emailIsValid(value)

    switch (name) {
      case "fname":
        setFormData({
          ...formData,
          firstName: { value: value, touched: true, valid: notNull },
        })
        break

      case "lname":
        setFormData({
          ...formData,
          lastName: { value: value, touched: true, valid: notNull },
        })
        break

      case "email":
        let emailErrorText = formData.email.errorText
          ? formData.email.errorText
          : ""
        if (notNull) {
          emailErrorText = !emailValid ? "Please enter a valid email" : ""
        } else {
          emailErrorText = "Email is required"
        }
        setFormData({
          ...formData,
          email: {
            value: value,
            touched: true,
            valid: notNull && emailValid,
            errorText: emailErrorText,
          },
        })
        break

      case "phone":
        setFormData({
          ...formData,
          phone: { value: value, touched: true, valid: true },
        })
        break

      case "message":
        setFormData({
          ...formData,
          message: { value: value, touched: true, valid: notNull },
        })
        break
    }
  }

  const [snackBar, setSnackBar] = React.useState({
    open: false,
    success: false,
    message: "",
  })

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return
    }
    setSnackBar({ ...snackBar, open: false })
  }

  const mailFunctionURI = "https://gentlecareambulance.com/mailer"

  const handleSubmit = () => {
    const sendAdminEmail = () => {
      const message = {
        email: "info@gentlecareambulance.com",
        subject: "New message from website user",
        html: `
                    <body>
                        <h1>New message from : ${
                          formData.firstName.value +
                          " " +
                          formData.lastName.value
                        }.</h1>
                        <table>
                            <tr>
                                <th>Email</th> <th>Phone</th>
                            </tr>
                            <tr>
                                <td>${formData.email.value}</td> <td>${
          formData.phone.value
        }</td>
                            </tr>
                        </table>
                        <p>${formData.message.value}</p>
                    </body>
                `,
      }

      return axios.post(mailFunctionURI, message)
    }

    const sendUserEmail = () => {
      const message = {
        email: formData.email.value,
        subject: "We recieved your message",
        html: `
                    <body>
                        <h1>Hi ${
                          formData.firstName.value +
                          " " +
                          formData.lastName.value
                        }.</h1>
                        <p>We got your message and will get back to you shortly</p>
                    </body>
                `,
      }

      return axios.post(mailFunctionURI, message)
    }

    axios.all([sendAdminEmail(), sendUserEmail()]).then(
      (res) => {
        setSnackBar({
          open: true,
          success: true,
          message: "We got your message and will get back to you shortly",
        })
        setFormData(initForm())
      },
      (error) => {
        setSnackBar({
          open: true,
          success: false,
          message: "Sorry, there was an error sending email. Try again later",
        })
      }
    )
  }

  const formStyles = css(`

        .controlGroup {
            margin: 2% 0;

            .textField {
                margin: 0 2%;
                width: 46%;
            }

            .fullWidth {
                width: 96%;
            }
        }

        .submitBtnDiv {
            margin: 2% 0;
            display: flex;
            justify-content: center;
        }

        button {
            background-color: blue;
            color: white;
            width: 30%;
        }
    `)

  const snackbarStyles = css(`
        * {
            background: ${snackBar.success ? "green" : "red"};
            color: white;
        }
    `)

  return (
    <form css={formStyles}>
      <div className="controlGroup">
        <TextField
          name="fname"
          className="textField"
          label="Your first name"
          variant="outlined"
          error={formData.firstName.touched && !formData.firstName.valid}
          helperText="First name is required"
          value={formData.firstName.value}
          onChange={handleChange}
        />

        <TextField
          name="lname"
          className="textField"
          label="Your last name"
          variant="outlined"
          error={formData.lastName.touched && !formData.lastName.valid}
          helperText="Last name is required"
          value={formData.lastName.value}
          onChange={handleChange}
        />
      </div>

      <div className="controlGroup">
        <TextField
          name="email"
          className="textField"
          label="Your email address"
          variant="outlined"
          error={formData.email.touched && !formData.email.valid}
          helperText={formData.email.errorText}
          value={formData.email.value}
          onChange={handleChange}
        />
        <TextField
          name="phone"
          className="textField"
          label="876-123-3456"
          variant="outlined"
          value={formData.phone.value}
          onChange={handleChange}
        />
      </div>

      <div className="controlGroup">
        <TextField
          name="message"
          className="textField fullWidth"
          label="I am interested in..."
          error={formData.message.touched && !formData.message.valid}
          helperText="Message is required"
          variant="outlined"
          value={formData.message.value}
          onChange={handleChange}
        />
      </div>

      <div className="submitBtnDiv">
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={!formValid}
        >
          Send
        </Button>
      </div>

      <Snackbar
        css={snackbarStyles}
        open={snackBar.open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={snackBar.message}
      />
    </form>
  )
}

export default EnquiryForm
